.container {
  height: 100vh;
  width: 100vw;

  display: flex;
  flex-direction: column;
}

.container2 {
  display: flex;
  overflow: hidden;
  height: 100%;
}

.container3 {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.notification {
}
.header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.navigation {
  overflow: auto;
  flex-shrink: 0;
  transition: width 0.2s ease;
}

.navigationOpen {
  width: 220px;
}

.navigationClose {
  overflow-x: hidden;
  width: 58px;
}

.main {
  grid-area: content;
  padding: 15px;
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
}

.notificationButton {
  padding: 6px 18px;
  color: inherit;
  width: 100%;
  font-size: 14px;
}
