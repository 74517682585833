.expire {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  gap: 1rem !important;
}

@media screen and (max-width: 488px) {
  .expire {
    justify-content: center !important;
    flex-wrap: wrap;
  }
}
