.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
}

.main {
  display: flex;
  gap: 2rem;
}

.form {
  flex-basis: 30%;
}

.brand {
  margin: auto;
  flex-basis: 30%;
  min-width: 300px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  max-width: 400px;
}

@media screen and (max-width: 650px) {
  .main {
    flex-wrap: wrap-reverse;
  }

  .form {
    flex-basis: unset;
  }

  .brand {
    flex-basis: unset;
  }
}
